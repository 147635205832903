import { cn } from "@/lib/utils";
import {
  InputHTMLAttributes,
  PropsWithChildren,
  SelectHTMLAttributes,
  useId,
} from "react";

export function Form({ children }: PropsWithChildren) {
  return <div className="weui-form">{children}</div>;
}

export interface FormBodyProps extends PropsWithChildren {
  title: string;
}

export function FormBody({ title, children }: FormBodyProps) {
  return (
    <div className="weui-form__bd">
      <div className="weui-form__text-area">
        <h2 className="weui-form__title">{title}</h2>
      </div>
      <div className="weui-form__control-area">
        {children}
      </div>
    </div>
  );
}

export function FormFooter({ children }: PropsWithChildren) {
  return (
    <div className="weui-form__ft">
      <div className="weui-form__opr-area">{children}</div>
    </div>
  );
}

interface FormCellBase {
  title: string;
  desc?: string;
}

interface CellFormSelectProps
  extends FormCellBase,
    Pick<SelectHTMLAttributes<HTMLSelectElement>, "value" | "onChange"> {
  options: string[];
}

export function CellFormSelect({
  title,
  desc,
  options,
  value,
  onChange,
}: CellFormSelectProps) {
  return (
    <div className="weui-cell weui-cell_active weui-cell_select weui-cell_select-after">
      <div className="weui-cell__hd">
        <span className="weui-label">{title}</span>
        {desc && <div className="weui-cell__desc">{desc}</div>}
      </div>
      <div className="weui-cell__bd">
        <select className="weui-select" value={value} onChange={onChange}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
export interface CellFormInputProps
  extends FormCellBase,
    Pick<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"|"type"|"disabled"> {
  placeholder?: string;
}
export function CellFormInput({
  type = "text",
  disabled,
  title,
  desc,
  placeholder,
  value,
  onChange,
}: CellFormInputProps) {
  const id = useId();
  return (
    <label htmlFor={id} className="weui-cell weui-cell_active">
      <div className="weui-cell__hd">
        <span className="weui-label">{title}</span>
        {desc && <div className="weui-cell__desc">{desc}</div>}
      </div>
      <div className="weui-cell__bd">
        <input
        disabled={disabled}
          id={id}
          className="weui-input"
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
        />
      </div>
    </label>
  );
}

export interface CellFormGroupProps extends PropsWithChildren {
  error?: string;
}

export function CellFormGroup({ children, error }: CellFormGroupProps) {
  return (
    <div className="weui-cells__group weui-cells__group_form">
      <div className="weui-cells">{children}</div>
      <div
        role="alert"
        className={cn("weui-cells__tips weui-cells__tips_warn", {
          hidden: !error,
        })}
      >
        {error}
      </div>
    </div>
  );
}
