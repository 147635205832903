import React, { InputHTMLAttributes, PropsWithChildren } from "react";
import { cn } from "@/lib/utils";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: "small" | "normal";
  variant?: "primary" | "default" | "warn";
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, disabled, variant = "primary", size = "normal", ...props },
    ref
  ) => {
    const base = ["weui-btn"];
    if (size === "small") {
      base.push("weui-btn_mini");
    }

    if (variant === "primary") {
      base.push("weui-btn_primary");
    } else if (variant === "warn") {
      base.push("weui-btn_warn");
    } else {
      base.push("weui-btn_default");
    }

    if (disabled) {
      base.push("weui-btn_disabled");
    }

    return (
      <button
        className={cn(base.join(" "), className)}
        ref={ref}
        disabled={disabled}
        {...props}
      />
    );
  }
);

export default Button;

export function FileButton({
  children,
  onChange,
}: PropsWithChildren &
  Pick<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  return (
    <label>
      <span className="weui-btn weui-btn_primary">{children}</span>
      <input
        type="file"
        className="hidden"
        accept="image/*"
        onChange={onChange}
      />
    </label>
  );
}
