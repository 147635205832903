import { ToastProps } from "@/components/toast";
import { Store } from "@tanstack/store";
import { useCallback, useEffect } from "react";
import { APIClient } from "@/api";
import { useStore } from "@tanstack/react-store";

type StoreType = {
    toast?: ToastProps;
    api: APIClient
}
export const globalStore = new Store<StoreType>({
    api: new APIClient(),
})


export function usePageTitle(title:string) {
    useEffect(() => {
        document.title = title
    },[ title])
}

export function useToast() {
    return useCallback((msg:string,icon?:ToastProps["icon"]) => {
        globalStore.setState((state) => {
            return {
                ...state,
                toast: {
                    message: msg,
                    icon
                }
            }
        })

        setTimeout(() => {
            globalStore.setState((state) => {
                return {
                    ...state,
                    toast: undefined
                }
            })
        }, 2000)
    },[])
}

export function useLoadingToast() {
    return [useCallback((msg:string="正在加载") => {
        globalStore.setState((state) => {
            return {
                ...state,
                toast: {
                    message: msg,
                    icon: "loading"
                }
            }
        })
    },[]),()=>{
        globalStore.setState((state) => {
            return {
                ...state,
                toast: undefined
            }
        })
    }]
}

export function useAPI() {
    return useStore(globalStore, (state) => state.api)
}