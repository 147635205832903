import { TaskResultModel } from "@/api";
import { FileButton } from "@/components/button";
import { useAPI, useLoadingToast, useToast } from "@/store";
import { useMutation } from "@tanstack/react-query";
import { X } from "lucide-react";
import { PropsWithChildren, useCallback } from "react";

export interface UploadProps extends PropsWithChildren {
  missionId: string;
  accountId: string;
  onChange?: () => void;
}
export interface ReUploadProps extends PropsWithChildren {
  taskResultId: string;
  onChange?: () => void;
}
export function UploadButton(props: UploadProps | ReUploadProps) {
  const api = useAPI();
  const { children, onChange } = props;
  const { mutate } = useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append("screenshot", file);
      if ("taskResultId" in props) {
        const { taskResultId } = props as ReUploadProps;
        return await api
          .collection<TaskResultModel>("task_results")
          .update(taskResultId, formData);
      } else {
        const { missionId, accountId } = props as UploadProps;
        formData.append("account", accountId);
        formData.append("mission", missionId);
        formData.append("user", api.authStore.model?.id);
        return await api
          .collection<TaskResultModel>("task_results")
          .create(formData);
      }
    },
  });

  const [ingToast, dismiss] = useLoadingToast();
  const toast = useToast();
  const upload = useCallback(
    async (file: File) => {
      ingToast("正在上传");
      await mutate(file, {
        onSuccess: () => {
          dismiss();
          toast("上传成功");
          onChange && onChange();
        },
        onError: () => {
          dismiss();
          toast("上传失败");
        },
      });
    },
    [dismiss, ingToast, mutate, onChange, toast]
  );
  return (
    <>
      <FileButton
        onChange={(e) => {
          const files = e.target.files;
          if (files && files.length > 0) {
            upload(files[0]);
          }
        }}
      >
        {children}
      </FileButton>
    </>
  );
}

export function ReUploadAction(props: ReUploadProps) {
  return (
    <>
      <p className="text-center p-6">
        <X className="inline-block w-6 h-6 text-red-500" />
        审核失败，原因为‘xxxx'，请核实后重新上传
      </p>
      <UploadButton {...props}>重新上传截图</UploadButton>
    </>
  );
}
