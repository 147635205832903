import { PropsWithChildren } from "react";

export function Cells({
  children,
  title,
}: PropsWithChildren & { title?: string }) {
  return (
    <>
      {title && <div className="weui-cells__title">{title}</div>}
      <div className="weui-cells">{children}</div>
    </>
  );
}

export function Cell({ children }: PropsWithChildren) {
  return <div className="weui-cell ">{children}</div>;
}

export function CellAccess({ children }: PropsWithChildren) {
  return <div className="weui-cell weui-cell_access">{children}</div>;
}

export function CellBody({ children }: PropsWithChildren) {
  return <div className="weui-cell__bd">{children}</div>;
}

export function CellFooter({ children }: PropsWithChildren) {
  return <div className="weui-cell__ft">{children}</div>;
}
