import BackButton from "@/components/back-button";
import Button from "@/components/button";
import { Panel } from "@/components/panel";
import { BlankImage, cn } from "@/lib/utils";
import { ErrorPage } from "@/routes/error-page";
import { differenceInHours, isBefore, parseISO } from "date-fns";
import { globalStore, useAPI, usePageTitle } from "@/store";
import { createFileRoute, Link, useRouter } from "@tanstack/react-router";
import { Check, ChevronRight, ExternalLink, UserPlus } from "lucide-react";
import { PropsWithChildren, useCallback, useState } from "react";
import EnrollAction from "./enroll";
import { ReUploadAction, UploadButton } from "./upload";
import { ExternalAccountModel, TaskResultModel } from "@/api";

export const Route = createFileRoute("/missions/$missionId")({
  component: MissionDetail,
  loader: async ({ params: { missionId } }) => fetchMission(missionId),
  errorComponent: ({ error }) => {
    return <ErrorPage error={error} />;
  },
});

function fetchMission(missionId: string) {
  const api = globalStore.state.api;
  return api.getMissionDetail(missionId);
}

function MissionDetail() {
  usePageTitle("任务详情");
  const data = Route.useLoaderData();
  const { missionId } = Route.useParams();
  const et = parseISO(data.expired_at);
  const hours = differenceInHours(et, new Date());
  const router = useRouter();

  const api = useAPI();
  const coverImage = data?.cover
    ? api.getFileUrl(
        {
          collectionName: "missions",
          id: data.id,
        },
        data.cover,
        { thumb: "100x100" }
      )
    : BlankImage;
  const finished = !!data.task?.screenshot && data.task.audit_result === "ok";
  const expired = data.status === "disabled" || isBefore(data.expired_at, new Date());
  const [copyTip, setCopyTip] = useState("复制链接");
  const executeCopy = useCallback(async () => {
    await navigator.clipboard.writeText(data.link);
    setCopyTip("复制成功");
  }, [data.link]);

  const handleChange = useCallback(() => {
    router.navigate({
      to: "/missions/$missionId",
      params: { missionId: missionId },
    });
  }, [missionId, router]);
  return (
    <div className="h-full">
      <div className="flex items-center justify-between p-4">
        <BackButton />
        <Link to="/account">我的账户</Link>
      </div>
      <Panel title="任务详情" more={false}>
        <div className="weui-cell flex items-center py-2 px-4 border-b-[1px]">
          <input
            type="checkbox"
            className="w-5 h-5 mr-3"
            checked={finished}
            disabled
          />
          <img className="w-10 h-10 mr-3 bg-zinc-300" src={coverImage} />
          <div className="flex-1 min-w-0">
            <h4
              className={cn(
                "min-w-0 whitespace-pre-wrap overflow-hidden",
                finished ? "line-through text-muted-foreground" : ""
              )}
            >
              {data?.title}
            </h4>
            <div className="text-xs space-x-2">
              <span className={hours > 24 ? "text-green-500" : "text-red-500"}>
                {hours > 24
                  ? `剩余${Math.round(hours / 24)}天`
                  : `剩余${hours}小时`}
              </span>
            </div>
          </div>
        </div>
        <div className="space-y-2 p-4">
          <DetailField label="平台">{data?.platform}</DetailField>
          <DetailField label="目标">{data.target}</DetailField>
          <DetailField label="要求" signleLine={false}>
            {data.description}
          </DetailField>
          <DetailField label="链接">
            <span className="inline-block py-2 max-w-48 min-w-0 overflow-hidden text-ellipsis">
              {data.link}
            </span>
            <Button size="small" className="ml-2" onClick={executeCopy}>
              {copyTip}
            </Button>
            <a
              className="weui-btn weui-btn_mini   weui-btn_primary inline-block ml-2"
              style={{ marginTop: 0 }}
              href={data.link}
              target="_blank"
            >
              打开链接
              <ExternalLink className=" inline-block ml-1 w-4 h-4" />
            </a>
          </DetailField>
        </div>
      </Panel>
      {/** 已过期 */}
      {expired && <p className="text-red-500 text-center p-6">任务已过期</p>}
      {/** 未过期 & 未领取 */}
      {!expired && !data.task && (
        <div className="px-2">
          <EnrollAction missionId={missionId} />
        </div>
      )}
      {!expired && data.task && (
        <p className="text-center p-6">已领取，完成后上传截图</p>
      )}
      {/** 未过期 & 已领取 */}
      {!expired &&
        data.task &&
        data.results.map((result, i) => {
          return (
            <AccountTaskReuslt
              key={i}
              account={result.account}
              missionId={missionId}
              data={result.result}
              onChange={handleChange}
            />
          );
        })}
      {/** 未过期 & 已领取 */}
      {!expired && data.task && data.results.length === 0 && (
        <p className="text-center py-4 px-2">
          您还未添加任何账号,
          <Link to="/account/upsert" className="inline-flex items-center">
            <UserPlus className="w-4 h-4 inline-block mx-2" />
            点击添加 <ChevronRight className="w-4 h-4 inline-block mx-2" />
          </Link>
        </p>
      )}
      <div className="text-center py-4">
        <div className="text-sm text-gray-500">
          提示：多个账号可以多次执行同一任务
        </div>
        <Link to="/account/upsert" className="inline-flex items-center">
          <UserPlus className="w-4 h-4 inline-block mx-2" />
          添加账号 <ChevronRight className="w-4 h-4 inline-block mx-2" />
        </Link>
      </div>

      <div className="text-center pt-6 text-gray-300">到底了</div>
      <div className="text-xs text-center text-gray-300">{data.id}</div>
    </div>
  );
}

interface AccountTaskResultProps {
  account: ExternalAccountModel;
  data?: TaskResultModel;
  missionId: string;
  onChange: () => void;
}
function AccountTaskReuslt({
  account,
  onChange,
  missionId,
  data,
}: AccountTaskResultProps) {
  const api = useAPI();
  const avatar = data?.avatar
    ? api.files.getUrl(account, account.avatar, { thumb: "100x100" })
    : BlankImage;
  return (
    <div className="px-2 flex items-center border-t py-2 justify-center">
      <div className="relative p-2">
        <img
          src={avatar}
          className="w-12 h-12 object-contain bg-gray-400 rounded-full"
        />
        <p className="text-sm px-2 absolute inset-0 text-white flex items-center">
          {account.username}
        </p>
      </div>
      <div>
        {!data && (
          <UploadButton
            missionId={missionId}
            accountId={account.id}
            onChange={onChange}
          >
            上传截图
          </UploadButton>
        )}

        {data &&
          data.screenshot &&
          (data.audit_result === "initial" || !data.audit_result) && (
            <>
              <Button className="my-6" disabled>
                已上传，审核中
              </Button>
            </>
          )}

        {data && data.audit_result === "reject" && (
          <ReUploadAction taskResultId={data.id} onChange={onChange} />
        )}

        {data && data.screenshot && data.audit_result === "ok" && (
          <p className="text-center py-6 max-w-56">
            <Check className="inline-block w-6 h-6 text-green-500" />
            审核通过，你已成功完成本次任务，已获得相应的任务奖励
          </p>
        )}
      </div>
    </div>
  );
}

interface DetailFieldProps extends PropsWithChildren {
  label: string;
  signleLine?: boolean;
}

function DetailField({ label, children, signleLine = true }: DetailFieldProps) {
  return (
    <div className="flex items-center">
      <label className="min-w-14 text-muted-foreground">{label}</label>
      <div
        className={cn(
          "flex-1 font-light min-w-0 overflow-hidden flex items-center py-2",
          signleLine
            ? "text-ellipsis whitespace-nowrap "
            : "whitespace-pre-wrap"
        )}
      >
        {children}
      </div>
    </div>
  );
}
