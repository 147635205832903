import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}


export interface PropsWithClassName {
  className?: string
}

export async function fetchImage(url: string) {
  const response = await fetch(url.replace("format/webp","format/jpg"),{
    mode:"cors",
    headers:{
    },
    referrerPolicy:"no-referrer"
  });
  // response.status = 0 by 'no-cors'
  // if(!response.ok){
  //   throw new Error(response.statusText);
  // }
  const blob = await response.blob();
  console.log("console.type",blob.type)
  if(blob.type === "image/png"){
    return new File([blob], "image.png", { type: blob.type });
  }
  return new File([blob], "image.jpg", { type: blob.type });
}

export const BlankImage = "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E"