import { ExternalAccount, ExternalAccountModel } from "@/api";
import BackButton from "@/components/back-button";
import Button from "@/components/button";
import {
  CellFormGroup,
  CellFormInput,
  CellFormSelect,
  Form,
  FormBody,
  FormFooter,
} from "@/components/form";
import { createImage } from "@/lib/image";
import { useAPI, useLoadingToast, usePageTitle } from "@/store";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";

export interface Props {
  data?: ExternalAccountModel;
}
export function UpsertSocialAccount({ data }: Props) {
  usePageTitle(`${data ? "编辑" : "创建"}自媒体账号`);
  const api = useAPI();
  const [username, setUsername] = useState(data?.username);
  const [website, setWebsite] = useState(data?.website || "小红书");
  const [link, setLink] = useState(data?.link);
  const [err, setErr] = useState("");
  const [toast, dismiss] = useLoadingToast();
  const [avatar, setAvatar] = useState<Blob>();
  const [avatarSrc, setAvatarSrc] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.avatar) {
      setAvatarSrc(api.files.getUrl(data, data.avatar, { thumb: "100x100" }));
    }
  }, [api.files, data]);

  const { mutate } = useMutation({
    mutationFn: async ({
      username,
      link,
      website,
    }: Pick<ExternalAccount, "username" | "link" | "website">) => {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("website", website);
      formData.append("link", link);

      if (data) {
        return api
          .collection<ExternalAccountModel>("external_accounts")
          .update(data.id, formData);
      } else {
        if (avatar) formData.set("avatar", avatar);
        formData.set("user", api.authStore.model?.id);
        return api
          .collection<ExternalAccountModel>("external_accounts")
          .create(formData);
      }
    },
  });

  const handleSubmit = useCallback(() => {
    if (!username || !website || !link) {
      setErr("请填写完整信息");
      return;
    }
    toast("提交中...");
    mutate(
      { username, website, link },
      {
        onSuccess: () => {
          dismiss();
          navigate({ to: "/account" });
        },
        onError: (e) => {
          dismiss();
          setErr(`创建失败，${e.message}`);
        },
      }
    );
  }, [dismiss, link, mutate, navigate, toast, username, website]);

  const fetchLink = useCallback(async (link: string) => {
    const pattern = "(https://www.xiaohongshu.com/user/profile/[\\S/]+)\\??.*";
    const match = link.match(pattern);
    if (!match) {
      setErr("链接格式不正确");
      return;
    }
    setErr("");
    setLink(match[1]);

    try {
      const coverImage = await createImage(200, 200);
      if (!coverImage) {
        throw new Error("封面抓取失败");
      }
      setAvatar(new File([coverImage], "image.png", { type: coverImage.type }));

      if (FileReader) {
        const fr = new FileReader();
        fr.onload = function () {
          setAvatarSrc(fr.result as string);
        };
        fr.readAsDataURL(coverImage);
      }
    } catch (e) {
      setErr(`抓取账号页面失败`);
    }
  }, []);
  return (
    <div className="h-full">
      <div className="flex items-center justify-between p-4">
        <BackButton />
      </div>
      <div className="flex justify-center px-4 py-2">
        {avatarSrc && (
          <img src={avatarSrc} className="w-20 h-20 rounded-full" />
        )}
      </div>
      <Form>
        <FormBody title={`${data ? "编辑" : "创建"}自媒体账号`}>
          <CellFormGroup error={err}>
            <CellFormSelect
              title="账号类型"
              options={["小红书"]}
              value={website}
              onChange={(e) => {
                setWebsite(e.target.value);
              }}
            />
            <CellFormInput
              type="url"
              title="账号链接"
              placeholder="请输入"
              value={link}
              onChange={(e) => {
                fetchLink(e.target.value);
              }}
            />
            <CellFormInput
              title="账号名"
              placeholder="请输入"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </CellFormGroup>
        </FormBody>
        <FormFooter>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit();
            }}
          >
            提交
          </Button>
        </FormFooter>
      </Form>
    </div>
  );
}
