export interface ToastProps {
  icon?: "success" | "warn" | "loading";
  message?: string;
}

export function Toast({ icon, message }: ToastProps) {
    let icnClass = "weui-icon-success-no-circle" 
    if(icon === "warn"){
        icnClass = "weui-icon-warn"
    }

    if(icon === "loading"){
        icnClass = "weui-primary-loading"
    }
  return (
    <>
      <div className="weui-mask_transparent"></div>
      <div className="weui-toast">
        <i
          className={`weui-icon_toast ${icnClass}`}
        />
        <p className="weui-toast__content">{message}</p>
      </div>
    </>
  );
}
