import { createRootRoute, Outlet, redirect } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { useStore } from "@tanstack/react-store";
import { globalStore } from "@/store";
import { Toast } from "@/components/toast";

export const Route = createRootRoute({
  beforeLoad: async ({ location }) => {
    const isSignIn = !!globalStore.state.api.user;
    if (location.pathname === "/auth") {
      //check if already signin
      if (isSignIn) {
        throw redirect({
          to: "/",
        });
      }
    } else {
      if (location.pathname !== "/auth/callback" && !isSignIn) {
        throw redirect({
          to: "/auth",
        });
      }
    }
  },
  component: Root,
});

function Root() {
  const toast = useStore(globalStore, (state) => state.toast);
  return (
    <>
      <div className="max-w-screen-sm h-full  overflow-x-hidden bg-[var(--weui-BG-1)]">
        <Outlet />
      </div>
      {import.meta.env.DEV && <TanStackRouterDevtools position="bottom-left" />}
      <div style={{ display: toast ? "block" : "none" }}>
        <Toast {...toast} />
      </div>
    </>
  );
}
