import BackButton from "@/components/back-button";
import { Loader } from "@/components/loader";
import { Panel } from "@/components/panel";
import { TaskRow } from "@/routes/task-row";
import { useAPI } from "@/store";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";

export const Route = createFileRoute("/mytasks/")({
  component: MyTasks,
});

function MyTasks() {
  const api = useAPI();
  const { data, isPending, error } = useQuery({
    queryKey: ["my-tasks"],
    queryFn: async () => {
      return await api.getMyTasks();
    },
  });
  return (
    <div className="h-full">
      <div className="flex items-center justify-between p-4">
        <BackButton/>
        <Link to="/account">我的账户</Link>
      </div>
      {isPending && (
        <div>
          <Loader />
        </div>
      )}
      {error && <div className="text-destructive">{error.message}</div>}
      <Panel title="已领取的任务" more={false}>
        {data?.tasks &&
          data?.tasks.map((task) => <TaskRow key={task.id} task={task} />)}
      </Panel>

      <div className="text-center p-6 text-gray-300">到底了</div>
    </div>
  );
}
