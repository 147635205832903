import { cn, PropsWithClassName } from "@/lib/utils";
import { Link, LinkProps } from "@tanstack/react-router";
import { PropsWithChildren } from "react";

export function Grids({
  children,
  className,
}: PropsWithChildren & PropsWithClassName) {
  return (
    <div
      className={cn(
        "grid grid-cols-3 gap-[1px] bg-[var(--weui-FG-3)]",
        className
      )}
    >
      {children}
    </div>
  );
}

export function Grid({
  children,
  className,
}: PropsWithChildren & PropsWithClassName) {
  return (
    <div
      className={cn(
        "flex flex-col  items-center justify-center p-4 gap-1 bg-white",
        className
      )}
    >
      {children}
    </div>
  );
}

export function GridLink({
  children,
  className,
  ...rest
}: PropsWithChildren & PropsWithClassName & LinkProps) {
  return (
    <Link
      {...rest}
      className={cn(
        "flex flex-col  items-center justify-center p-4 gap-1 bg-white",
        className
      )}
    >
      {children}
    </Link>
  );
}
