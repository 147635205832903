import { createFileRoute, Link } from "@tanstack/react-router";
import { useAPI, usePageTitle } from "@/store";
import { Panel } from "@/components/panel";
import { BlankImage, cn } from "@/lib/utils";
import { TaskRow } from "../task-row";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "@/components/loader";
import { MissionModel } from "@/api";
import { differenceInHours, parseISO } from "date-fns";

export const Route = createFileRoute("/missions/")({
  component: Home,
});

function Home() {
  usePageTitle("首页");
  const api = useAPI();
  const { data, isPending, error } = useQuery({
    queryKey: ["home-tasks-missions"],
    queryFn: async () => {
      return await api.getHomeMissions();
    },
  });
  return (
    <div className="h-full">
      <div className="flex items-center justify-between p-4">
        <div className="w-8 h-8 rounded-full bg-black overflow-hidden">
          avatar
        </div>
        <Link to="/account">我的账户</Link>
      </div>
      {isPending && (
        <div>
          <Loader />
        </div>
      )}
      {error && <div className="text-destructive">{error.message}</div>}

      <Panel title="已领取的任务" moreTo="/mytasks" moreText="全部任务">
        {data?.tasks &&
          data?.tasks.map((task) => <TaskRow key={task.id} task={task} />)}
      </Panel>
      <Panel title="最新任务" more={false}>
        {data?.missions &&
          data?.missions.map((mission) => (
            <MissionRow key={mission.id} mission={mission} />
          ))}
      </Panel>
      <div className="text-center p-6 text-gray-300">到底了</div>
    </div>
  );
}

type MissionRowProps = {
  mission: MissionModel;
};
function MissionRow({ mission }: MissionRowProps) {
  const api = useAPI();
  const coverImage = mission.cover
    ? api.getFileUrl(mission, mission.cover, { thumb: "100x100" })
    : BlankImage;
  const hours = differenceInHours(
    parseISO(mission.available_until),
    new Date()
  );
  return (
    <div className="weui-cell  flex items-center py-0 px-4">
      <img className="w-10 h-10 mr-3 bg-zinc-300" src={coverImage} />
      <div className="flex-1 min-w-0 py-2 pr-2">
        <h4
          className={cn(
            "min-w-0 whitespace-nowrap text-ellipsis overflow-hidden"
          )}
        >
          {mission.title}
        </h4>
        <span className="text-xs mr-1 text-white px-1 leading-5 inline-block rounded bg-red-500">
          {mission.platform}
        </span>
      </div>
      <div className="weui-vcode-btn leading-5 py-2 pr-0 w-24 overflow-hidden">
        <Link
          className=""
          to="/missions/$missionId"
          params={{ missionId: mission.id }}
        >
          领取
        </Link>
        <p className="text-xs text-muted-foreground">
          {hours > 72 ? `${Math.round(hours / 24)}天` : `${hours}小时`}后下架
        </p>
      </div>
    </div>
  );
}
