import { ExternalAccountModel } from "@/api";
import { ErrorPage } from "@/routes/error-page";
import { UpsertSocialAccount } from "@/routes/~account/upsert-form";
import { globalStore } from "@/store";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/account/upsert/$accountId")({
  component: EditSocialAccount,
  loader: ({ params: { accountId } }) => fetechAccount(accountId),
  errorComponent: ({ error }) => {
    return <ErrorPage error={error} />;
  },
});

async function fetechAccount(accountId: string) {
  return globalStore.state.api
    .collection<ExternalAccountModel>("external_accounts")
    .getOne(accountId);
}

export function EditSocialAccount() {
  const data = Route.useLoaderData();
  return <UpsertSocialAccount data={data} />;
}
