import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute("/auth/")({
  component: RedirectWWSignin,
});
const cropID = "wwc202e1aefa7ee756";
const agentID = "1000003";

function RedirectWWSignin() {
  useEffect(() => {
    const origin = window.location.origin;
    if (origin.includes("localhost") || origin.includes("127.0.0.1")) {
      return;
    }
    const redirect_uri = encodeURIComponent(
      `${window.location.origin}/auth/callback`
    );
    const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${cropID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE&agentid=${agentID}#wechat_redirect`;
    window.location.href = url;
  }, []);
  return <></>;
}
