import { useNavigate, useRouter } from "@tanstack/react-router";
import { ChevronLeft } from "lucide-react";

export default function BackButton() {
  const navigate = useNavigate()
  const router = useRouter()
  return (
    <button className="flex items-center" onClick={() => {
      console.log(router.routeTree)
      
      navigate({to:"/"})
    }}>
      <ChevronLeft className="h-4 w-4 inline-block mr-2" />
      返回
    </button>
  );
}
