import { Link, LinkProps } from "@tanstack/react-router";
import { PropsWithChildren } from "react";

interface PanelProps extends PropsWithChildren {
  title: string;
  more?: boolean;
  moreTo?: LinkProps["to"];
  moreText?: string;
}
export function Panel({ children, title, more = true, moreTo ,moreText}: PanelProps) {
  return (
    <div className="weui-panel">
      <div className="weui-panel__hd ">{title}</div>
      <div className="weui-panel__bd ">{children}</div>
      {more && (
        <div className="weui-panel__ft">
          <Link
            to={moreTo}
            className="weui-cell weui-cell_access weui-cell_link"
          >
            <div className="weui-cell__bd ">{!moreText && "更多"}</div>
            <div className="weui-cell__ft ">{moreText}</div>
          </Link>
        </div>
      )}
    </div>
  );
}
