import { useAPI } from "@/store";
import { createFileRoute } from "@tanstack/react-router";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";

export const Route = createFileRoute("/auth/callback")({
  component: SigninCallback,
  validateSearch: (search): { code: string } => {
    return {
      code: search.code as string,
    };
  },
});

function SigninCallback() {
  const { code } = Route.useSearch();
  const api = useAPI();
  const navigate = Route.useNavigate();

  const {
    isPending,
    mutate: signin,
    error,
  } = useMutation({
    mutationKey: ["signin"],
    mutationFn: async (code: string) => {
      const res = await api.authWithCode(code);
      console.log(res);
      return res;
    },
  });

  useEffect(() => {
    signin(code, {
      onSuccess: (data) => {
        console.log(data);
        navigate({ to: "/" });
      },
    });
  }, [code, navigate, signin]);
  return (
    <div>
      {isPending && <span className="weui-primary-loading__dot"></span>}
      {error && <span className="text-red-500">{error.message}</span>}
      <span className="bg-white text-gray-400">[{code}]</span>
    </div>
  );
}
