import Button from "@/components/button";
import { useAPI, useToast, useLoadingToast } from "@/store";
import { useMutation } from "@tanstack/react-query";
import { useRouter } from "@tanstack/react-router";

export default function EnrollAction({ missionId }: { missionId: string }) {
  const router = useRouter();
  const api = useAPI();
  const { mutate } = useMutation({
    mutationKey: ["enrollMission"],
    mutationFn: async (id: string) => {
      return api.enrollMission(id);
    },
  });
  const toast = useToast();
  const [showLoading, dismiss] = useLoadingToast();
  return (
    <>
      <p className="text-center p-6">你尚未领取该任务</p>
      <Button
        onClick={() => {
          showLoading("正在领取");
          mutate(missionId, {
            onSuccess: () => {
              dismiss();
              toast("领取成功");
              router.invalidate();
            },
            onError: () => {
              dismiss();
              toast("领取失败");
            },
          });
        }}
      >
        立即领取
      </Button>
    </>
  );
}
