import { Link } from "@tanstack/react-router";

export function ErrorPage({ error }: { error: Error }) {
    return (
      <div>
        <h1>Oops!</h1>
        <p>发生了一个异常错误，请稍后重试.<Link to="/">返回首页</Link></p>
        <p>
          <i className="text-red-500">{error.message}</i>
        </p>
      </div>
    );
  }