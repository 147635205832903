import { TaskStatus } from "@/api";
import { BlankImage, cn } from "@/lib/utils";
import { useAPI } from "@/store";
import { useNavigate } from "@tanstack/react-router";
import { differenceInHours, parseISO } from "date-fns";

interface TaskRowProps {
  task: TaskStatus;
}
export function TaskRow({ task }: TaskRowProps) {
  const navigate = useNavigate();
  const api = useAPI()
  const coverImage = task.cover?api.getFileUrl({
    collectionName:"missions",
    id:task.mission
  },task.cover,{thumb: "100x100"}):BlankImage;
  const finished = task.status === "done";
  const auditing = task.status === "uploaded"
  const hours = differenceInHours(parseISO(task.expired_at), new Date())
  return (
    <div
      className="weui-cell flex items-center py-2 px-4"
      onClick={() =>
        navigate({
          to: "/missions/$missionId",
          params: { missionId: task?.mission },
        })
      }
    >
      <input
        type="checkbox"
        className="w-5 h-5 mr-3"
        checked={finished}
        disabled
      />
      <img
        className="w-10 h-10 mr-3 bg-zinc-300"
        src={coverImage}
      />
      <div className="flex-1 min-w-0">
        <h4
          className={cn(
            "min-w-0 whitespace-nowrap text-ellipsis overflow-hidden",
            finished ? "line-through text-muted-foreground" : "",
          )}
        >
          {task?.title}
        </h4>
        <div className="text-xs space-x-2">
          {hours>0 && <span className={hours>24?"text-green-500":"text-red-500"}>{hours>24?`剩余${Math.round(hours/24)}天`:`剩余${hours}小时`}</span>}
          {hours<=0 && <span className="text-gray-500">已过期</span>}
          {auditing && <span className="text-blue-500">审批中</span>}
        </div>
      </div>
    </div>
  );
}
