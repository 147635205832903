import { createFileRoute } from "@tanstack/react-router";
import { useAPI, usePageTitle, useToast } from "@/store";
import { Plus } from "lucide-react";
import { Cell, CellBody, CellFooter, Cells } from "@/components/cells";
import Button from "@/components/button";
import { Grid, GridLink, Grids } from "@/components/grids";
import BackButton from "@/components/back-button";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ExternalAccountModel } from "@/api";
import { BlankImage } from "@/lib/utils";
import { useCallback } from "react";

export const Route = createFileRoute("/account/")({
  component: Account,
});

function Account() {
  usePageTitle("我的账户");
  const api = useAPI();
  const { data, refetch } = useQuery({
    queryKey: ["cnt-tasks"],
    queryFn: async () => {
      return api.getAccountSummary();
    },
  });
  const threshold = 2;
  const avaiable = data?.credits.available || 0;
  const withdraw = data?.credits.withdraw || 0;

  const { data: accounts, isPending: isAccountsPending } = useQuery({
    queryKey: ["accounts"],
    queryFn: async () => {
      return api
        .collection<ExternalAccountModel>("external_accounts")
        .getFullList({
          filter: `user="${api.authStore.model?.id}"`,
        });
    },
  });

  const { mutate: withdrawOp } = useMutation({
    mutationFn: async () => {
      return api.withdrawCredits();
    },
  });

  const toast = useToast();
  const handleWithdraw = useCallback(async () => {
    // TODO: 
    // eslint-disable-next-line no-constant-condition
    if(true){
      toast("内测期间，暂不支持自动提现，请联系工作人员");
      return 
    }
    withdrawOp(undefined, {
      onSuccess: () => {
        toast("提现成功,到账后系统会发送通知");
        refetch();
      },
      onError: () => {
        toast("提现失败,请重试或联系管理员");
      },
    });
  }, [refetch, toast, withdrawOp]);
  return (
    <div className="h-full">
      <div className="flex items-center justify-between p-4">
        <BackButton />
      </div>
      <Cells title="自媒体账号">
        <Grids>
          {isAccountsPending && (
            <Grid>
              <i className={`weui-icon_toast weui-primary-loading`} />
            </Grid>
          )}
          {accounts &&
            accounts?.map((account) => (
              <SocialAccount data={account} key={account.id} />
            ))}
          <GridLink to="/account/upsert" className="text-gray-500">
            <Plus className="h-6 w-6" />
            <span>点击添加</span>
          </GridLink>
        </Grids>
      </Cells>

      <Cells title="任务">
        <Cell>
          <CellBody>已完成</CellBody>
          <CellFooter>{data?.tasks.ok || 0}</CellFooter>
        </Cell>
        <Cell>
          <CellBody>审核中</CellBody>
          <CellFooter>{data?.tasks.initial || 0}</CellFooter>
        </Cell>
        <Cell>
          <CellBody>审核失败</CellBody>
          <CellFooter>{data?.tasks.reject || 0}</CellFooter>
        </Cell>
      </Cells>

      <Cells title="账户奖励（元）">
        <Cell>
          <CellBody>累记</CellBody>
          <CellFooter>{avaiable + withdraw}</CellFooter>
        </Cell>
        <Cell>
          <CellBody>已提现</CellBody>
          <CellFooter>{withdraw}</CellFooter>
        </Cell>
        <Cell>
          <CellBody>可提现</CellBody>
          <CellFooter>{avaiable}</CellFooter>
        </Cell>
      </Cells>
      {avaiable < threshold && (
        <div className="text-center px-6 mt-2 text-orange-400 text-sm">
          可提现余额超过{threshold}才能提现
        </div>
      )}
      <Button
        className="mt-4"
        onClick={handleWithdraw}
        disabled={avaiable < threshold}
      >
        申请提现
      </Button>

      <div className="text-center p-6 text-gray-300">到底了</div>
    </div>
  );
}

function SocialAccount({ data }: { data: ExternalAccountModel }) {
  const api = useAPI();
  const avatar = data?.avatar
    ? api.files.getUrl(data, data.avatar, { thumb: "100x100" })
    : BlankImage;
  return (
    <GridLink
      className=""
      to="/account/upsert/$accountId"
      params={{ accountId: data.id }}
    >
      <img src={avatar} className="w-10 h-10" />
      <span className="text-xs text-white px-2 leading-5 block rounded-xl bg-red-500">
        {data.website}
      </span>
      <p className="text-sm w-24 whitespace-nowrap text-ellipsis overflow-hidden  ">
        {data.username}
      </p>
    </GridLink>
  );
}
